import MomentUtils from "@date-io/moment";
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import debounce from "lodash.debounce";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UPDATE_BENEFICIARY_FILLED_DATA } from "../Assets/Constant/BeneficiaryForm";
import {
  REMOVE_FLEX_FORM_ERROR,
  REQUEST_TAC,
} from "../Assets/Constant/CompleteProfileForm";

const useStyles = makeStyles({
  disabledBackgroundColor: {
    backgroundColor: "#D3D3D3",
  },
  tooltipLabel: {
    fontSize: "small",
    color: "#5D65F8",
    fontWeight: "bold",
    fontStyle: "italic",
    textDecoration: "none",
  },
  icon: {
    fontSize: "16px",
    marginBottom: "-3px",
    marginRight: "5px",
  },
});

export const Label = ({ data, onChange, size }) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const onChangeForm = (name, val) => {
    setValue(val);
    setError(false);
    if (data.error) {
      dispatch({
        type: REMOVE_FLEX_FORM_ERROR,
        data: data.name,
      });
    }
  };

  const onBlur = (name, val) => {
    onChange(name, val, data.entity_type, data.type);
    setError(false);
  };

  useEffect(() => {
    if (data.value) {
      setValue(data.value);
      onChange(data.name, data.value, data.entity_type, data.type);
    }
  }, [data.value]);

  useEffect(() => {
    if (data.error) setError(data.error);
  }, [data.error]);

  return (
    <Grid
      item
      md={size ? size : 4}
      xs={6}
      style={{ display: !data.visible ? "none" : "" }}
    >
      <p style={{ textAlign: "center" }}>{data.label}</p>
      <br />
      <p
        style={{
          textAlign: "center",
          marginBlockStart: "0rem",
          color: "#F15A24",
        }}
      >
        {value}
      </p>
    </Grid>
  );
};

export const AutoCompleteInput = ({ data, onChange, onSearch }) => {
  const [selectValue, setSelectValue] = useState();
  const [inputText, setInputText] = useState();
  const [placeKey] = useState(data.place_id_key);
  const [error, setError] = useState(false);
  const classes = useStyles();

  const onChangeValue = (name, value, entityType) => {
    onChange(name, value, entityType, placeKey);
    setSelectValue(value);
    setInputText(value.value);
    setError(false);
  };

  const debounceText = debounce((val) => {
    if (val) onSearch(data.name, val, data.entity_type, placeKey);
  }, 500);

  const onChangeText = (name, value, entityType) => {
    debounceText(value);
  };

  // useEffect(() => {
  //   if(textValue) onChange(data.name, textValue, data.entity_type, placeKey);
  // }, [textValue])

  useEffect(() => {
    if (data.error) setError(data.error);
  }, [data.error]);

  useEffect(() => {
    if (data.value) {
      const newData = {
        value: data.value,
        label: data.value,
      };
      setSelectValue(newData);
      setInputText(data.value);
      onChange(data.name, newData, data.entity_type, placeKey);
    }
  }, [data.value]);

  return (
    <Grid item md={6} xs={12} style={{ display: !data.visible ? "none" : "" }}>
      {data.label} <br />
      <Autocomplete
        freeSolo
        id={data.name}
        disableClearable
        options={data.suggestions}
        autoComplete={false}
        value={selectValue ? selectValue : null}
        inputValue={inputText}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option}
        onChange={(item, value) =>
          onChangeValue(data.name, value, data.entity_type)
        }
        // onInputChange={(item, value) => onChangeValue(data.name, value, data.entity_type)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            onChange={(e) =>
              onChangeText(
                data.name,
                {
                  label: data.label,
                  value: e.target.value,
                },
                data.entity_type
              )
            }
            error={data.error && data.error}
            placeholder={data.placeholder}
            autofill={data.autofill}
            margin="dense"
            color="primary"
            className={data.disable ? classes.disabledBackgroundColor : ""}
          />
        )}
        disabled={data.disable}
      />
      {error &&
        data.messages &&
        data.messages.map((item, idx) => {
          return (
            <FormHelperText
              key={idx}
              id="component-helper-text"
              style={{ color: "red" }}
            >
              {item}
            </FormHelperText>
          );
        })}
    </Grid>
  );
};

export const NumberInput = ({ data, onChange, size }) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const classes = useStyles();

  const onChangeForm = (name, val) => {
    setValue(val);
    setError(false);
    if (data.error) {
      dispatch({
        type: REMOVE_FLEX_FORM_ERROR,
        data: data.name,
      });
    }
  };

  const onBlur = (name, val) => {
    onChange(name, val, data.entity_type, data.type);
    setError(false);
  };

  useEffect(() => {
    if (data.value) {
      setValue(data.value);
      onChange(data.name, data.value, data.entity_type, data.type);
    }
  }, [data.value]);

  useEffect(() => {
    if (data.error) setError(data.error);
  }, [data.error]);

  return (
    <Grid
      item
      md={size ? size : 6}
      xs={12}
      style={{ display: !data.visible ? "none" : "" }}
    >
      {data.label} <br />
      <TextField
        id={data.name}
        autoComplete={false}
        name={data.name}
        type="number"
        placeholder={data.placeholder}
        onBlur={(event) => onBlur(data.name, event.target.value)}
        onChange={(event) => onChangeForm(data.name, event.target.value)}
        variant="outlined"
        fullWidth
        value={value}
        margin="dense"
        color="primary"
        required={data.required}
        disabled={data.disable}
        error={data.error}
        className={data.disable ? classes.disabledBackgroundColor : ""}
      />
      {error &&
        data.messages &&
        data.messages.map((item, idx) => {
          return (
            <FormHelperText
              key={idx}
              id="component-helper-text"
              style={{ color: "red" }}
            >
              {item}
            </FormHelperText>
          );
        })}
    </Grid>
  );
};

export const GeneralInput = ({ data, onChange, size }) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const classes = useStyles();

  const onChangeForm = (name, val) => {
    setValue(val);
    setError(false);
    if (data.error) {
      dispatch({
        type: REMOVE_FLEX_FORM_ERROR,
        data: data.name,
      });
    }
  };

  const onBlur = (name, val) => {
    onChange(name, val, data.entity_type, data.type);
    setError(false);
  };

  useEffect(() => {
    if (data.value) {
      setValue(data.value);
      onChange(data.name, data.value, data.entity_type, data.type);
    }
  }, [data.value]);

  useEffect(() => {
    if (data.error) setError(data.error);
  }, [data.error]);

  return (
    <Grid
      item
      md={size ? size : 6}
      xs={12}
      style={{ display: !data.visible ? "none" : "" }}
    >
      {data.label}
      {data.label_info ? (
        <>
          <Tooltip
            title={
              data.label_info.description
                ? data.label_info.description
                : data.label_info?.url
            }
          >
            {data.label_info.url ? (
              <a
                href={data.label_info.url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.tooltipLabel}
                style={{ paddingLeft: "8px" }}
              >
                <OpenInNewIcon className={classes.icon} />
                {data.label_info.message}
              </a>
            ) : (
              <Button
                variant="text"
                className={classes.tooltipLabel}
                style={{ padding: "0 8px" }}
              >
                {data.label_info.message}
              </Button>
            )}
          </Tooltip>
        </>
      ) : null}
      <br />
      <TextField
        id={data.name}
        autoComplete={false}
        name={data.name}
        placeholder={data.placeholder}
        onBlur={(event) => onBlur(data.name, event.target.value)}
        onChange={(event) => onChangeForm(data.name, event.target.value)}
        variant="outlined"
        fullWidth
        value={value}
        margin="dense"
        color="primary"
        required={data.required}
        disabled={data.disable}
        autofill={data.autofill}
        error={data.error}
        className={data.disable ? classes.disabledBackgroundColor : ""}
      />
      {error &&
        data.messages &&
        data.messages.map((item, idx) => {
          return (
            <FormHelperText
              key={idx}
              id="component-helper-text"
              style={{ color: "red" }}
            >
              {item}
            </FormHelperText>
          );
        })}
    </Grid>
  );
};

export const TACInput = ({ data, onChange, size }) => {
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const classes = useStyles();

  const onChangeForm = (name, val) => {
    setValue(val);
    onChange(name, val, data.entity_type, data.type);
    setError(false);
    if (data.error) {
      dispatch({
        type: REMOVE_FLEX_FORM_ERROR,
        data: data.name,
      });
    }
  };

  const onClickButton = (e) => {
    setDisabled(true);
    setTimeout(() => setDisabled(false), data.refresh_timer * 60000);
    dispatch({
      type: REQUEST_TAC,
      data: {
        expiry: "5",
      },
    });
  };

  useEffect(() => {
    if (data.value) {
      setValue(data.value);
      //WHAT IS THIS FOR? MYSELF IN THE FUTURE
      // onChange(data.name, data.value, data.entity_type, data.type);
    }
  }, [data.value]);

  useEffect(() => {
    if (data.error) setError(data.error);
  }, [data.error]);

  return (
    <>
      <Grid
        item
        md={size ? size : 4}
        xs={12}
        style={{ display: !data.visible ? "none" : "" }}
      >
        {data.label} <br />
        <TextField
          id={data.name}
          name={data.name}
          autocomplete={false}
          placeholder={data.placeholder}
          onChange={(event) => onChangeForm(data.name, event.target.value)}
          variant="outlined"
          fullWidth
          value={value}
          margin="dense"
          color="primary"
          required={data.required}
          disabled={data.disable}
          autofill={data.autofill}
          error={data.error}
          className={data.disable ? classes.disabledBackgroundColor : ""}
        />
        {error &&
          data.messages &&
          data.messages.map((item, idx) => {
            return (
              <FormHelperText
                key={idx}
                id="component-helper-text"
                style={{ color: "red" }}
              >
                {item}
              </FormHelperText>
            );
          })}
      </Grid>
      <Grid
        item
        md={size ? size : 2}
        xs={12}
        style={{
          display: !data.visible ? "none" : "",
          paddingTop: 42,
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={(e) => onClickButton(e)}
          disabled={disabled}
        >
          Request TAC
        </Button>
        {data.messages &&
          data.messages.map((item, idx) => {
            return (
              <FormHelperText
                key={idx}
                id="component-helper-text"
                style={{ color: "red" }}
              >
                {item}
              </FormHelperText>
            );
          })}
      </Grid>
    </>
  );
};

export const SelectInput = ({ data, onChange, size }) => {
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const classes = useStyles();

  const onChangeValue = (name, value, group) => {
    setError(false);
    dispatch({
      type: REMOVE_FLEX_FORM_ERROR,
      data: data.name,
    });

    onChange(name, value, group);
    setValue(value);
  };

  const onBlur = (name, strValue, group) => {
    const options = Array.from(data.options);
    const newOption = options.find((option) => (option.label.toLowerCase() === strValue.toLowerCase()));
    const newInputValue = newOption ? newOption.label : "" ;

    setInputValue(newInputValue);
    setValue(newOption);
    onChange(name, newOption, group);
  };

  useEffect(() => {
    if (data.value) {
      const defaultVal = data.options.find((item) => item.value === data.value);
      if (defaultVal) {
        setValue(defaultVal);
        setInputValue(defaultVal.label);
        onChange(data.name, defaultVal, data.entity_type, data.type);
      }
    } else {
      setValue("");
      setInputValue("");
      if (data.name === "payout_method") {
        dispatch({
          type: UPDATE_BENEFICIARY_FILLED_DATA,
          data: {
            [data.name]: "",
            __target: data.name,
          },
        });
        return;
      }
      onChange(data.name, null, data.entity_type, data.type);
    }
  }, [data.value]);

  useEffect(() => {
    if (data.error) setError(data.error);
  }, [data.error]);

  return (
    <Grid
      item
      md={size ? size : 6}
      xs={12}
      style={{ display: !data.visible ? "none" : "" }}
    >
      {data.label}
      {data.label_info ? (
        <>
          <Tooltip
            title={
              data.label_info.description
                ? data.label_info.description
                : data.label_info?.url
            }
          >
            {data.label_info.url ? (
              <a
                href={data.label_info.url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.tooltipLabel}
                style={{ paddingLeft: "8px" }}
              >
                <OpenInNewIcon className={classes.icon} />
                {data.label_info.message}
              </a>
            ) : (
              <Button
                variant="text"
                className={classes.tooltipLabel}
                style={{ padding: "0 8px" }}
              >
                {data.label_info.message}
              </Button>
            )}
          </Tooltip>
        </>
      ) : null}
      <br />
      <Autocomplete
        autoComplete={false}
        id={data.name}
        disableClearable
        options={data.options}
        value={value}
        onChange={(item, value) =>
          onChangeValue(data.name, value, data.field_group)
        }
        onBlur={(event) =>
          onBlur(data.name, event.target.value, data.field_group)
        }
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            error={data.error && data.error}
            placeholder={data.placeholder}
            margin="dense"
            color="primary"
            className={data.disable ? classes.disabledBackgroundColor : ""}
          />
        )}
        disabled={data.disable}
        autofill={data.autofill}
      />
      {error &&
        data.messages &&
        data.messages.map((item, idx) => {
          return (
            <FormHelperText
              key={idx}
              id="component-helper-text"
              style={{ color: "red" }}
            >
              {item}
            </FormHelperText>
          );
        })}
    </Grid>
  );
};

export const DateInput = ({ data, onChange, size }) => {
  const dispatch = useDispatch();
  const [selectedDate, setDateChange] = useState();
  const [error, setError] = useState(false);

  const onChangeDate = (e) => {
    setDateChange(moment(e).format("DD MMMM yyyy"));
    setError(false);
    dispatch({
      type: REMOVE_FLEX_FORM_ERROR,
      data: data.name,
    });
    onChange(data.name, moment(e).format("yyyy-MM-DD"), data.entity_type);
  };

  useEffect(() => {
    if (data.value) {
      setDateChange(moment(data.value).format("DD MMMM yyyy"));
      onChange(data.name, data.value, data.entity_type, data.type);
    }
  }, [data.value]);

  useEffect(() => {
    if (data.error) setError(data.error);
  }, [data.error]);

  return (
    <Grid
      item
      md={size ? size : 6}
      xs={12}
      style={{ display: !data.visible ? "none" : "" }}
    >
      {data.label} <br />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          value={selectedDate}
          onChange={onChangeDate}
          variant="inline"
          inputVariant="outlined"
          size="small"
          autofill={data.autofill}
          fullWidth
          autoOk
          format={"DD MMMM yyyy"}
          style={{ paddingTop: 7 }}
        />
      </MuiPickersUtilsProvider>
      {error &&
        data.messages &&
        data.messages.map((item, idx) => {
          return (
            <FormHelperText
              key={idx}
              id="component-helper-text"
              style={{ color: "red" }}
            >
              {item}
            </FormHelperText>
          );
        })}
    </Grid>
  );
};

export const MobileNumberInput = ({ data, onChange, size }) => {
  const [value, setValue] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    dispatch({
      type: REMOVE_FLEX_FORM_ERROR,
      data: data.name,
    });
    if (countryCode && phoneNumber) {
      onChange({
        phone_country_code: countryCode,
        phone_number: phoneNumber,
      });
      setError(false);
    }
  }, [phoneNumber, countryCode]);

  //terrible side effect need to rework
  useEffect(() => {
    //rework on this value to check if object key exists with value or not
    if (data.value) {
      setPhoneNumber(data.value.phone_number);
      setCountryCode(data.value.phone_country_code);
    }

    if (data.value) {
      const defaultVal = {
        label: `+${data.value.phone_country_code}`,
        value: data.value.phone_country_code,
      };
      setValue(defaultVal);
      setInputValue(data.value);
      setError(false);
      // onChange(data.name, defaultVal, data.entity_type, data.type);
      onChange(
        data.name,
        `${data.value.phone_country_code + data.value.phone_number}`,
        data.entity_type,
        data.type
      );
    }
  }, [data.value]);

  useEffect(() => {
    if (data.error) setError(data.error);
  }, [data.error]);

  return (
    <Grid
      item
      md={size ? size : 6}
      xs={12}
      style={{ display: !data.visible ? "none" : "" }}
    >
      Mobile Number
      <Box display="flex" flexDirection="row" flexWrap="nowrap">
        <Box style={{ marginRight: 5 }}>
          <Autocomplete
            autoComplete={false}
            disableClearable
            options={data.options}
            getOptionLabel={(option) => option.label}
            value={value}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={(item, value) => setCountryCode(value.value)}
            renderInput={(params) => (
              <TextField
                {...params}
                error={data.error && data.error}
                variant="outlined"
                margin="dense"
                color="primary"
                className={data.disable ? classes.disabledBackgroundColor : ""}
              />
            )}
            disabled={data.disable}
            autofill={data.autofill}
          />
        </Box>
        <Box flexGrow={1}>
          <TextField
            autoComplete={false}
            onBlur={(event) => setPhoneNumber(event.target.value)}
            onChange={(event) => setPhoneNumber(event.target.value)}
            // value={phoneNumber}
            placeholder={data.placeholder}
            value={phoneNumber}
            variant="outlined"
            margin="dense"
            color="primary"
            fullWidth
            autofill={data.autofill}
            disabled={data.disable}
            error={data.error && data.error}
            className={data.disable ? classes.disabledBackgroundColor : ""}
          />
        </Box>
      </Box>
      {error &&
        data.messages &&
        data.messages.map((item, idx) => {
          return (
            <FormHelperText
              key={idx}
              id="component-helper-text"
              style={{ color: "red" }}
            >
              {item}
            </FormHelperText>
          );
        })}
    </Grid>
  );
};

export const BankInput = ({ data, onChange, size }) => {
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState("");
  const [optionData, setOptionData] = useState([]);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const classes = useStyles();

  const onChangeValue = (name, value, entityType) => {
    dispatch({
      type: REMOVE_FLEX_FORM_ERROR,
      data: data.name,
    });
    setError(false);
    onChange(name, value, entityType);
    setValue(value);
  };

  useEffect(() => {
    if (data.value) {
      const defaultVal = {
        label: data.value,
        value: data.value,
      };
      setValue(defaultVal);
      setInputValue(data.value);
      onChange(data.name, defaultVal, data.entity_type, data.type);
    }
  }, [data.value]);

  useEffect(() => {
    if (data.options && data.options.length !== 0) {
      setOptionData([...data.options]);
    }
  }, [data.options]);

  useEffect(() => {
    if (data.error) setError(data.error);
  }, [data.error]);

  return (
    <Grid
      item
      md={size ? size : 6}
      xs={12}
      style={{ display: !data.visible ? "none" : "" }}
    >
      {data.label} <br />
      <Autocomplete
        autoComplete={false}
        id={data.name}
        options={optionData}
        disableClearable
        // options={options}
        value={value}
        onChange={(item, value) =>
          onChangeValue(data.name, value, data.entity_type)
        }
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            error={data.error && data.error}
            placeholder={data.placeholder}
            margin="dense"
            color="primary"
            className={data.disable ? classes.disabledBackgroundColor : ""}
          />
        )}
        disabled={data.disable}
        autofill={data.autofill}
      />
      {error &&
        data.messages &&
        data.messages.map((item, idx) => {
          return (
            <FormHelperText
              key={idx}
              id="component-helper-text"
              style={{ color: "red" }}
            >
              {item}
            </FormHelperText>
          );
        })}
    </Grid>
  );
};

export const TickBox = ({ data, onChange }) => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    onChange(data.name, event.target.checked, data.entity_type, data.type);
  };

  useEffect(() => {
    if (data.value) {
      // setValue(data.value);
      onChange(data.name, data.value, data.entity_type, data.type);
    }
  }, [data.value]);

  return (
    <Grid
      item
      md={12}
      xs={12}
      style={{ display: data.visible ? "block" : "none" }}
    >
      <Checkbox
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "primary checkbox" }}
      />
      {data.label}
      {/* <TextField
        id={data.name}
        autoComplete={false}
        name={data.name}
        placeholder={data.placeholder}
        onBlur={(event) => onBlur(data.name, event.target.value)}
        onChange={(event) => onChangeForm(data.name, event.target.value)}
        variant="outlined"
        fullWidth
        value={value}
        margin="dense"
        color="primary"
        required={data.required}
        disabled={data.disable}
        error={data.error}
      /> */}
      {data.messages &&
        data.messages.map((item, idx) => {
          return (
            <FormHelperText
              key={idx}
              id="component-helper-text"
              style={{ color: "red" }}
            >
              {item}
            </FormHelperText>
          );
        })}
    </Grid>
  );
};
