import {
  GET_EDIT_MISSING_SENDER_INFO_FORM,
  GET_EDIT_MISSING_SENDER_INFO_FORM_SUCCESS,
  GET_EDIT_MISSING_SENDER_INFO_FORM_FAIL,
  VERIFY_EDIT_MISSING_SENDER_INFO_FORM,
  VERIFY_EDIT_MISSING_SENDER_INFO_FORM_SUCCESS,
  VERIFY_EDIT_MISSING_SENDER_INFO_FORM_FAIL,
  SUBMIT_EDIT_MISSING_SENDER_INFO_FORM,
  SUBMIT_EDIT_MISSING_SENDER_INFO_FORM_SUCCESS,
  SUBMIT_EDIT_MISSING_SENDER_INFO_FORM_FAIL,
  UPDATE_MISSING_SENDER_INFO_FILLED_DATA,
  RESET_MISSING_SENDER_INFO_FILLED_DATA,
} from "../Assets/Constant/MissingSenderInfoForm";
import {REMOVE_FLEX_FORM_ERROR} from "../Assets/Constant/CompleteProfileForm";
import payloadTransformer from "./PayloadTransformer";

const initialState = {
  loading: false,
  data: [],
  error: null,
  filledData: {},
};

const missingSenderInfo = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MISSING_SENDER_INFO_FILLED_DATA:
      const payload = payloadTransformer({filledData: state.filledData, updatedData: action.data});
      return {
        ...state,
        filledData: payload.filteredFilledData
      }
    case RESET_MISSING_SENDER_INFO_FILLED_DATA:
      return {
        ...state,
        filledData: {}
      }
    case GET_EDIT_MISSING_SENDER_INFO_FORM:
      return {
        ...state,
        loading: true,
      };
    case GET_EDIT_MISSING_SENDER_INFO_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data.fields,
      };
    case GET_EDIT_MISSING_SENDER_INFO_FORM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case REMOVE_FLEX_FORM_ERROR:
      state.data = state.data.map((item) => {
        if (item.name === action.data) {
          item.error = null;
          item.messages = [];
        }
        return item;
      })
      return state;
    case VERIFY_EDIT_MISSING_SENDER_INFO_FORM:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_EDIT_MISSING_SENDER_INFO_FORM_SUCCESS:
      return {
        ...state,
        data: state.data.map(obj => action.data.changes.find(o => o.name === obj.name) || obj),
        loading: false,
      };
    case VERIFY_EDIT_MISSING_SENDER_INFO_FORM_FAIL:
      const newData = state.data.map(obj => action.data.changes.find(o => o.name === obj.name) || obj);
      const newDataWithErrors = newData.map(obj => {
        let result = action.errors.filter(id => obj.name === id.name);
        if (result.length > 0) {
          return {
            ...obj,
            messages: result[0].messages,
            error: true
          }
        }
        return obj;
      })
      return {
        ...state,
        data: newDataWithErrors,
        loading: false,
      };
    case SUBMIT_EDIT_MISSING_SENDER_INFO_FORM:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_EDIT_MISSING_SENDER_INFO_FORM_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_EDIT_MISSING_SENDER_INFO_FORM_FAIL:
      return {
        ...state,
        data: state.data.map(obj => {
          let result = action.errors.filter(id => obj.name === id.name);
          if (result.length > 0) {
            return {
              ...obj,
              messages: result[0].messages,
              error: true
            }
          }
          return obj;
        }),
        loading: false,
      };
    default:
      return state;
  }
};

export default missingSenderInfo;
