function payloadTransformer ({filledData, updatedData}){
    let data = {
        ...filledData,
        ...updatedData,
    }

    return {
        filteredFilledData: Object.keys(data)
            .filter((key) => data[key] !== null && data[key] !== "")
            .reduce((result, key) => {
            result[key] = data[key]
            return result;
            }, {})
    }
}

export default payloadTransformer;